import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Keuzemenu2 = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role !== "Admin") {
                navigate("/spelen");
            }
        }
    }, []);

    return (
        <div className="keuzemenu">
            <div className="keuzes"> 
                <h4>Welkom, Admin! Kies wat je wilt gaan doen</h4>
                <Link to="/admin/codes">Spelcodes en verlengen</Link>
                <ul className="keuzelist">
                    <li>
                        <Link to="/admin/data">Download data</Link>
                    </li> 
                    <li>
                        <Link to="/admin/feedback">Feedback inzien</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Keuzemenu2;
