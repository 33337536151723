import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Feedback = () => {
    const [rating, setRating] = useState(null);
    const [comment, setComment] = useState('');
    const [verbeteringen, setVerbeteringen] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role !== "Notulist") {
                navigate("/spelen");
            }
        }
    }, [navigate]);

    const handleRatingClick = (value) => {
        if (rating === value) {
            setRating(null);
            const buttons = document.getElementsByClassName("feedbackbutton");
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].style.backgroundColor = "rgb(0, 238, 255)";
            }
            return;
        } else {
        setRating(value);
            const buttons = document.getElementsByClassName("feedbackbutton");
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].style.backgroundColor = "rgb(0, 238, 255)";
            }
            buttons[value - 1].style.backgroundColor = "rgb(3, 127, 136)";
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (rating === null) {
            alert("Geef eerst een rating voordat je feedback kan geven");
            return;
        }
        if (comment.trim() === '') {
            alert("Geef eerst feedback voordat je het kan indienen");
            return;
        }
        sendFeedback();
    };

    const sendFeedback = async () => {
        if (rating === "" || comment ==="") {
            alert("Geef eerst feedback voordat je het kan indienen");
            return;
        }
        try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/addfeedback`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        nummer: rating,
                        details: comment,
                    }),
                });
                const responseData = await response.json();
                if (verbeteringen !== '') {
                    sendBugfix();
                } else {
                    localStorage.removeItem("token");
                    window.location.reload();
                }
            } catch (error) {
                console.error("Fout met afronding indienen:", error);
            }
        };
    
    const sendBugfix = async () => {
        try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/addbugreport`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        details: verbeteringen,
                    }),
                });
                const responseData = await response.json();
                console.log(responseData);
                localStorage.removeItem("token");
                window.location.reload();
            } catch (error) {
                console.error("Fout met afronding indienen:", error);
            }
        };

    const handleOverslaan = async () => {
        localStorage.removeItem("token");
                window.location.reload();
    };

    
    return (
        <div className="feedbackcontainer2">
            <div className="feedbackcontainer">
                <h1>Hoe heb je het spel ervaren?</h1>
                <div className="textareadiv">
                    <div className="textareas">
                        <h5>Wat vond je van het spel?</h5>
                        <div className="feedbackbuttoncontainer">
                            {[1, 2, 3, 4, 5].map((value) => (
                                <button
                                    key={value}
                                    className="feedbackbutton"
                                    onClick={() => handleRatingClick(value)}
                                >
                                    <p>{['😠', '😕', '😐', '🙂', '😊'][value - 1]}</p>
                                </button>
                            ))}
                        </div>
                        <textarea
                            className="feedbackaera"
                            placeholder="Vertel ons wat meer over wat je van het spel vond..."
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>
                    <div className="textareas">
                        <h5>Heb je nog opmerkingen of feedback over de website?</h5>
                        <textarea
                            className="feedbackaera"
                            placeholder="Vertel ons waar je graag verandering in wilt zien..."
                            value={verbeteringen}
                            onChange={(e) => setVerbeteringen(e.target.value)}
                        />
                    </div>
                </div>
                <div className="feedbackbuttons">
                    <button
                        className="upperbuttons feedbackbuttonoverslaan"
                        onClick={handleOverslaan}
                    >
                        Overslaan
                    </button>
                    <button
                        className="upperbuttons feedbackbuttonsave"
                        onClick={handleSubmit}
                    >
                        Geef feedback
                    </button>

                </div>
            </div>
        </div>
    );
};

export default Feedback;
