import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Antwoordpagina = () => {
    const { gameId, name } = useParams();
    const [antwoord, setAntwoord] = useState("");
    const [card, setCard] = useState("");
    const [active, setActive] = useState(false);
    const [allAnswers, setAllAnswers] = useState([]);
    const [allComments, setAllComments] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [optieA, setOptieA] = useState('');
    const [optieB, setOptieB] = useState('');
    const [optieC, setOptieC] = useState('');
    const [cardName, setCardName] = useState('');
    const [cardCode, setCardCode] = useState('');
    const [question, setQuestion] = useState('');
    const [comment, setComment] = useState('');
    const [cardFound, setCardFound] = useState(false);
    const [index, setIndex] = useState(0);
    const navigate = useNavigate();

    // Check authentication and role
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role !== "Notulist") {
                navigate("/spelen");
            }
        }
    }, [navigate]);

    // Apply styles based on card code
    useEffect(() => {
        if (active) {
            applyStyles();
        }
    }, [cardCode, card, active]);

    // Handle option change
    const handleOptionChange = (e) => {
        let newSelectedOptions = [...selectedOptions];
        if (selectedOptions.includes(e.target.value)) {
            newSelectedOptions = selectedOptions.filter((option) => option !== e.target.value);
        } else {
            newSelectedOptions = [...selectedOptions, e.target.value];
        }
        setSelectedOptions(newSelectedOptions);
    };

    // Fetch card details
    const getCard = async (cardCode) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/getcard/${cardCode}`);
            const data = await response.json();
            if (!data) {
                setCardFound(false);
                alert("Kaart niet gevonden");
            } else {
                setCardFound(true);
                setCardName(data.name);
                setCardCode(data.code);
                setQuestion(data.question);
                setOptieA(data.optieA);
                setOptieB(data.optieB);
                setOptieC(data.optieC);
                setActive(true);
                applyStyles();
            }
        } catch (error) {
            console.error('Fout met ophalen van kaart:', error);
        }
    };

    // Apply styles based on card code
    const applyStyles = () => {
        const titelbalk = document.querySelector(".titelbalk");
        const vraagbalk = document.querySelector(".vraagbalk");
        const opties = document.querySelectorAll("h10");
        const cardCodeChar = cardCode.charAt(0);

        const styles = {
            F: ["rgb(250,175,24)", "rgb(253,227,174)", "rgb(252,214,139)"],
            G: ["rgb(243,110,49)", "rgb(250,204,183)", "rgb(248,182,151)"],
            V: ["rgb(200,32,38)", "rgb(229,184,185)", "rgb(227,143,146)"],
            R: ["rgb(144,38,143)", "rgb(216,179,215)", "rgb(199,146,198)"],
            S: ["rgb(16,116,188)", "rgb(171,206,231)", "rgb(135,185,221)"],
            W: ["rgb(21,192,242)", "rgb(173,223,250)", "rgb(137,223,248)"],
            O: ["rgb(0,124,143)", "rgb(166,209,215)", "rgb(127,189,198)"],
            A: ["rgb(58,181,74)", "rgb(186,229,191)", "rgb(156,217,164)"],
            default: ["rgb(50,50,50)", "rgb(60,60,60)"]
        };

        const [titelColor, vraagColor, optieColor] = styles[cardCodeChar] || styles.default;
        titelbalk.style.backgroundColor = titelColor;
        vraagbalk.style.backgroundColor = vraagColor;
        opties.forEach(optie => optie.style.backgroundColor = optieColor);
    };

    // Handle form submission
    const handleSubmit = async (type) => {
        const newAnswers = [...allAnswers];
        const newComments = [...allComments];
        const newQuestions = [...allQuestions];

        newAnswers[index] = selectedOptions;
        newComments[index] = comment;
        newQuestions[index] = card;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/antwoord/${gameId}/${name}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    cardName: [card], // Send single card at a time
                    answer: [selectedOptions], // Send answers as array of arrays
                    comment: [comment], // Send comments as array
                }),
            });

            await response.json();
            setCardFound(false);
            setActive(false);
            setAllAnswers(newAnswers);
            setAllComments(newComments);
            setAllQuestions(newQuestions);
        } catch (error) {
            console.error('Fout met data versturen:', error);
        }
    };

    // Handle next button click
    const handleNext = async () => {
        if (!card) {
            alert("Vul een kaart in");
            return;
        }

        if (selectedOptions.length === 0) {
            alert("Vul een antwoord in");
            return;
        }

        if (selectedOptions.some(option => option.startsWith("D - ")) && !antwoord) {
            alert("Vul een antwoord bij D in");
            return;
        }

        if (allQuestions.includes(card) && allQuestions.indexOf(card) !== index) {
            alert("Deze kaart is al ingevuld");
            return;
        }

        try {
            await handleSubmit("next");
            setIndex(index + 1);

            if (index + 1 < allAnswers.length) {
                setCard(allQuestions[index + 1]);
                getCard(allQuestions[index + 1]);
                setComment(allComments[index + 1]);
                setSelectedOptions(allAnswers[index + 1]);

                if (allAnswers[index + 1].some(option => option.startsWith("D - "))) {
                    setAntwoord(allAnswers[index + 1].find(option => option.startsWith("D - ")).substring(4));
                } else {
                    setAntwoord('');
                }
            } else {
                setCard('');
                setComment('');
                setSelectedOptions([]);
                setAntwoord('');
            }
        } catch (error) {
            console.error('Fout met data versturen:', error);
        }
    };

    // Handle previous button click
    const handlePrevious = async () => {
        if (index === 0) {
            alert("Dit is de eerste vraag");
            return;
        }

        if (card && selectedOptions.length === 0) {
            alert("Vul een antwoord in");
            return;
        }

        if (selectedOptions.some(option => option.startsWith("D - ")) && !antwoord) {
            alert("Vul een antwoord bij D in");
            return;
        }

        try {
            await handleSubmit("previous");
            setIndex(index - 1);
            setCard(allQuestions[index - 1]);
            getCard(allQuestions[index - 1]);
            setComment(allComments[index - 1]);
            setSelectedOptions(allAnswers[index - 1]);

            if (allAnswers[index - 1].some(option => option.startsWith("D - "))) {
                setAntwoord(allAnswers[index - 1].find(option => option.startsWith("D - ")).substring(4));
            } else {
                setAntwoord('');
            }
        } catch (error) {
            console.error('Fout met data versturen:', error);
        }
    };

    // Handle submit and close
    const handleSubmitAndClose = async () => {
        if (card && selectedOptions.length === 0) {
            alert("Vul een antwoord in");
            return;
        }

        if (selectedOptions.some(option => option.startsWith("D - ")) && !antwoord) {
            alert("Vul een antwoord bij D in");
            return;
        }

        if (window.confirm("U staat op het punt om door te gaan. Weet u zeker dat u dit wilt doen?")) {
            await handleSubmit("next1");
            navigate(`/matrix/${gameId}/${name}`);
        }
    };

    // Check if option is selected
    const checkSelected = (option) => {
        if (option.startsWith("D - ")) {
            return selectedOptions.some(opt => opt.startsWith("D - "));
        }
        return selectedOptions.includes(option);
    };

    // Handle open answer change
    const openAntwoordChange = (e) => {
        if (selectedOptions.some(option => option.startsWith("D - "))) {
            setSelectedOptions(selectedOptions.filter(option => !option.startsWith("D - ")));
        }
        setAntwoord(e.target.value);
    };

    return (
        <div className="antwoordpage">
            <div className="antwoordencontainer">
                {cardFound ? (
                    <div className="antwoorden">
                        <div className="titelbalk">
                            <h6 className='cardname'>{cardName}</h6>
                            <h6>|</h6>
                            <h5>{cardCode}</h5>
                        </div>
                        <div className="vraagbalk">
                            <h7>{question}</h7>
                        </div>
                        <div className="antwoordbalk">
                            {active && (
                                <form className="antwoordform">
                                    <div className="antwoordopties">
                                        <input
                                            type="checkbox"
                                            value={`A - ${optieA}`}
                                            checked={checkSelected(`A - ${optieA}`)}
                                            onChange={handleOptionChange}
                                        />
                                        <h10>A</h10>
                                        <h8>{optieA}</h8>
                                    </div>
                                    <div className="antwoordopties">
                                        <input
                                            type="checkbox"
                                            value={`B - ${optieB}`}
                                            checked={checkSelected(`B - ${optieB}`)}
                                            onChange={handleOptionChange}
                                        />
                                        <h10>B</h10>
                                        <h8>{optieB}</h8>
                                    </div>
                                    <div className="antwoordopties">
                                        <input
                                            type="checkbox"
                                            value={`C - ${optieC}`}
                                            checked={checkSelected(`C - ${optieC}`)}
                                            onChange={handleOptionChange}
                                        />
                                        <h10>C</h10>
                                        <h8>{optieC}</h8>
                                    </div>
                                    <div className="antwoordopties">
                                        <input
                                            type="checkbox"
                                            value={`D - ${antwoord}`}
                                            checked={checkSelected(`D - ${antwoord}`)}
                                            onChange={handleOptionChange}
                                        />
                                        <h10>D</h10>
                                        <input
                                            type="text"
                                            name="antwoord"
                                            onChange={openAntwoordChange}
                                            placeholder="Open antwoord:"
                                            value={antwoord}
                                        />
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                ) : (
                    <p>Kies een kaart</p>
                )}
            </div>
            <div className="antwoordpagesidebar">
                <h1>Vraag {index + 1}</h1>
                <div className="antwoordinputcontainer">
                    <div>
                        <h1>Vul hier de kaartcode in:</h1>
                        <br />
                        <input
                            type="text"
                            name="kaartcode"
                            placeholder="Kaartcode"
                            onChange={(e) => setCard(e.target.value.toUpperCase())}
                            value={card}
                        />
                        <br />
                        <br />
                        <button className="upperbuttons" onClick={() => getCard(card)}>Zoek Kaart</button>
                    </div>
                    <h1>Opmerking:</h1>
                    <textarea
                        type="text"
                        placeholder="Leg uit hoe jullie op dit antwoord zijn gekomen."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <button className="upperbuttons" onClick={handleNext}>
                        <div className="buttontext">
                            <p>{">"}</p>Volgende Vraag<p>{">"}</p>
                        </div>
                    </button>
                    <button className="upperbuttons" onClick={handlePrevious}>
                        <div className="buttontext">
                            <p>{"<"}</p>Vorige Vraag<p>{"<"}</p>
                        </div>
                    </button>
                </div>
                <button className="upperbuttons" onClick={handleSubmitAndClose}>
                    <div className="buttontext">
                        <p>{"X"}</p>Naar Fase 2<p>{"X"}</p>
                    </div>
                </button>
            </div>
        </div>
    );
};

export default Antwoordpagina;
