import { Link } from "react-router-dom";
import logo from "./../pictures/logo.png";
import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [notulist, setNotulist] = useState(false);
    const [spelbegeleider, setSpelbegeleider] = useState(false);
    const [admin, setAdmin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            if (decoded.role === "Notulist"){
                setNotulist(true);
                setSpelbegeleider(false);
            } else if (decoded.role === "Spelbegeleider"){
                setSpelbegeleider(true);
                setNotulist(false);
            } else if (decoded.role === "Admin"){
                setAdmin(true);
                setNotulist(false);
                setSpelbegeleider(false);
            }
        }
    }, [navigate]);

    useEffect(() => {
        const tokenFromStorage = localStorage.getItem('token');
        setToken(tokenFromStorage);

        if (!window.googleTranslateElementInit) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            document.head.appendChild(script);

            window.googleTranslateElementInit = () => {
                new window.google.translate.TranslateElement(
                    { pageLanguage: 'nl', includedLanguages: 'en,fr', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE },
                    'google_translate_element'
                );
            };
        }
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        setToken(null);
        window.location.reload();
    };
    const handleLogin = () => {
        navigate('/spelen');
    }

    return (
        <header>
            <div className="navbar">
                <ul>
                    <li><img src={logo} alt="png"/></li>
                    {!notulist && (
                    <>
                        <li className="navopties navoptiehome"><a href="https://workplacegame.nl/" target="_blank" rel="noopener noreferrer">Homepagina</a></li>
                    </>
                    )}
                    {spelbegeleider && (
                        <>
                            <li className="navopties navoptiedash"><Link to="/spelenkeuze">Dashboard</Link></li>
                        </>
                    )}
                    {admin && (
                        <>
                            <li className="navopties navoptiedash"><Link to="/admin">Dashboard</Link></li>
                        </>
                    )}
                    {spelbegeleider && (
                        <li className="navopties">
                            <button onClick={() => navigate('/account')}>Account</button>
                        </li>
                    )}
                    {token ? (
                        <li className="navopties">
                            <button onClick={handleLogout}>Log uit</button>
                        </li>
                    ) : (
                        <li className="navopties">
                            <button onClick={handleLogin}>Log in</button>
                        </li>
                    )}
                </ul>
                <div id="google_translate_element"></div>
            </div>
        </header>
    );
};

export default Navbar;
