import React, { useState, useEffect } from 'react';

const CaptchaComponent = ({ onCaptchaSuccess, setCaptchaValid }) => {
  const [generatedNumber, setGeneratedNumber] = useState(null);
  const [captcha, setCaptcha] = useState('');
  const [userInput, setUserInput] = useState('');

  // Function to convert number to Dutch word representation
  const numberToDutchWord = (number) => {
    const ones = ["", "een", "twee", "drie", "vier", "vijf", "zes", "zeven", "acht", "negen"];
    const tens = ["", "tien", "twintig", "dertig", "veertig", "vijftig", "zestig", "zeventig", "tachtig", "negentig"];
    const teens = ["tien", "elf", "twaalf", "dertien", "veertien", "vijftien", "zestien", "zeventien", "achttien", "negentien"];

    if (number < 10 || number > 99) {
      return ""; // Handle error or invalid number
    }

    if (number < 20) {
      return teens[number - 10];
    }

    const onesDigit = number % 10;
    const tensDigit = Math.floor(number / 10);

    return (onesDigit > 0 ? ones[onesDigit] + "en" : "") + tens[tensDigit];
  };

  // Function to generate a random captcha
  const generateCaptcha = () => {
    const number = Math.floor(10 + Math.random() * 90); // Generate a number between 10 and 99
    const dutchNumber = numberToDutchWord(number);

    setGeneratedNumber(number);
    setCaptcha(dutchNumber);
  };

  // Handle input change for user captcha input
  const handleCaptchaInput = (e) => {
    setUserInput(e.target.value);
  };

  // Verify if the user's input matches the generated number
  const verifyCaptcha = () => {
    if (parseInt(userInput) === generatedNumber) {
      onCaptchaSuccess();
      setCaptchaValid(true); 
    } else {
      setCaptchaValid(false);
      alert("Captcha incorrect");
    }
  };

  // Generate a new captcha when the component mounts
  useEffect(() => {
    generateCaptcha();
  }, []);

  return (
    <div className="captchadiv">
      <p>Vul het volgende nummer in: {captcha}</p>
      <input type="text" value={userInput} onChange={handleCaptchaInput} />
      <button type="button" className="upperbuttons" onClick={verifyCaptcha}>Verifieer Captcha</button>
    </div>
  );
};

export default CaptchaComponent;
