import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const Notulistlogin = () => {
  const [code, setCode] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [gameId, setGameId] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.role === "Notulist") {
        setGameId(decoded.gameId);
        setName(decoded.name);
        setLoggedIn(true);
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(`Attempting to login with Notulist code: ${code}`);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login/notulist/${code}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (data.status === "Error" && data.message === "No active game session or already filled") {
        alert("Geen actieve game gevonden of al ingevuld");
        return;
      } else if (response.ok && data.status === "Success") {
        // Set the required items in local storage          
        localStorage.setItem("token", data.token);
        window.location.reload();
        // Redirect to the antwoordpagina with the specific game session ID
      } else {
        // Handle errors or unsuccessful login
        console.error("Login niet gelukt:", data.message);
      }
    } catch (error) {
      console.error('Error tijdens de inlog:', error);
    }
  };

  if (loggedIn) {
    navigate(`/antwoordpagina/${gameId}/${name}`);
  }

  const backToSpelen = () => {
    navigate('/spelen');
  };

  return (
    <div className="login-register">
      <div className="accountdiv">
        <h5>Rapporteur Login</h5>
        <button className="upperbuttons" onClick={backToSpelen}>Terug</button>
        <br />
        <h5>Voer uw rapporteur code in:</h5>
        <input
          type="text"
          minLength="6"
          maxLength="6"
          placeholder="12345a"
          onChange={(e) => setCode(e.target.value)}
          value={code}
        />
        <button className="upperbuttons" onClick={handleSubmit}>Login</button>
      </div>
    </div>
  );
};

export default Notulistlogin;
