import React from 'react';
import '../index.css';

const CardModal = ({ show, handleClose, cardDetails }) => {
    if (!show) return null;

    const { code, name, question, answer, comment } = cardDetails;

    const colorMap = {
        'F': ["rgb(250,175,24)", "rgb(253,227,174)", "rgb(252,214,139)"],
        'G': ["rgb(243,110,49)", "rgb(250,204,183)", "rgb(248,182,151)"],
        'V': ["rgb(200,32,38)", "rgb(229,184,185)", "rgb(227,143,146)"],
        'R': ["rgb(144,38,143)", "rgb(216,179,215)", "rgb(199,146,198)"],
        'S': ["rgb(16,116,188)", "rgb(171,206,231)", "rgb(135,185,221)"],
        'W': ["rgb(21,192,242)", "rgb(173,223,250)", "rgb(137,223,248)"],
        'O': ["rgb(0,124,143)", "rgb(166,209,215)", "rgb(127,189,198)"],
        'A': ["rgb(58,181,74)", "rgb(186,229,191)", "rgb(156,217,164)"],
        'default': ["rgb(50,50,50)", "rgb(60,60,60)"]
    };
    const colors = colorMap[code.charAt(0)] || colorMap['default'];

    return (
        <div className="modal-overlay1" onClick={(e) => {e.stopPropagation(); handleClose()}}>
            <div className="modal-content1" onClick={e => e.stopPropagation()}>
                <div className="antwoorden1">
                    <div className="titelbalk" style={{ backgroundColor: colors[0] }}>
                        <h6 className='cardname'>{name}</h6>
                        <h6>|</h6>
                        <h5>{code}</h5>
                    </div>
                    <div className="vraagbalk" style={{ backgroundColor: colors[1] }}>
                        <h7>{question}</h7>
                    </div>
                    <div className="antwoordbalk">
                        <div className="antwoordnotesdiv">
                            <h7 className="underline">Antwoord:</h7>
                            {answer.map((ans, idx) => (
                                <h7 key={`answer-${idx}`}>{ans}</h7>
                            ))}
                        </div>   
                        <div className="antwoordnotesdiv">    
                            <h7 className="underline">Notitie:</h7>
                            <h7>{comment}</h7>
                        </div> 
                    </div>
                </div>
                <button style={{ backgroundColor: colors[0] }} onClick={handleClose} className="upperbuttons1">Close</button>
            </div>
        </div>
    );
};

export default CardModal;
