import React, { useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Custom hook to parse query parameters
function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const NieuwWachtwoord = () => {
    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const navigate = useNavigate();
    const query = useQuery();
    const token = query.get('token');
    const codeId = query.get('codeId');

    // Password validation function
    const validatePassword = (password) => password.length >= 8;

    // Handle changes to the new password input
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setFormData((prevData) => ({ ...prevData, newPassword }));
        setPasswordIsValid(validatePassword(newPassword));
        setPasswordsMatch(newPassword === formData.confirmPassword);
    };

    // Handle changes to the confirm password input
    const handleConfirmPasswordChange = (e) => {
        const confirmPassword = e.target.value;
        setFormData((prevData) => ({ ...prevData, confirmPassword }));
        setPasswordsMatch(formData.newPassword === confirmPassword);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!passwordIsValid || !passwordsMatch) {
            alert('Please ensure the passwords match and meet the requirements.');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/change-password`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    codeId,
                    newPassword: formData.newPassword
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText);
            }
            const data = await response.json();
            if (data.status === "Success") {
                alert('Wachtwoord succesvol bijgewerkt!');
                navigate('/login/spelbegeleider');
            } else {
                alert(data.message);
            }
        } catch (error) {
            alert('Error bij het resetten van wachtwoord: ' + error.message);
        }
    };

    return (
        <div className="login-register">
            <div className="accountdiv">
                <h5>Reset Wachtwoord</h5>
                <button className="upperbuttons" onClick={() => navigate('/login/spelbegeleider')}>Terug</button>
                <h5>Nieuw Wachtwoord:</h5>
                <input 
                    type="password" 
                    name="newPassword" 
                    onChange={handlePasswordChange} 
                    value={formData.newPassword} 
                />
                <h5>Bevestig Nieuw Wachtwoord:</h5>
                <input 
                    type="password" 
                    name="confirmPassword" 
                    onChange={handleConfirmPasswordChange} 
                    value={formData.confirmPassword} 
                />
                {!passwordsMatch && <div className="password-warning"><p>Wachtwoorden komen niet overeen</p></div>}
                {!passwordIsValid && <div className="password-warning"><p>Password does not meet requirements.</p></div>}
                <button 
                    className="upperbuttons" 
                    onClick={handleSubmit} 
                    disabled={!passwordsMatch || !passwordIsValid}
                >
                    Wachtwoord Resetten
                </button>
            </div>
        </div>
    );
};

export default NieuwWachtwoord;
