import React, { useState, useEffect } from 'react';
import CardModalMatrix from '../components/CardModalMatrix';

const RenderDroppedCard = ({ answer, questions, cardName, answers, comments }) => {
  const [showModal, setShowModal] = useState(false);
  const [cardDetails, setCardDetails] = useState({});
  const cardIndex = Number(answer.split(' ')[0]) - 1;

  const cardColorMap = {
    'F': ["rgb(250,175,24)", "rgb(253,227,174)"],
    'G': ["rgb(243,110,49)", "rgb(250,204,183)"],
    'V': ["rgb(200,32,38)", "rgb(229,184,185)"],
    'R': ["rgb(144,38,143)", "rgb(216,179,215)"],
    'S': ["rgb(16,116,188)", "rgb(171,206,231)"],
    'W': ["rgb(21,192,242)", "rgb(173,223,250)"],
    'O': ["rgb(0,124,143)", "rgb(166,209,215)"],
    'A': ["rgb(58,181,74)", "rgb(186,229,191)"],
  };
  const [titleColor, questionColor] = cardColorMap[questions[cardIndex].charAt(0)] || ["rgb(50,50,50)", "rgb(60,60,60)"];

  useEffect(() => {
    const titleElement = document.getElementsByClassName(`titel${questions[cardIndex]}`)[0];
    const questionElement = document.getElementsByClassName(`vraag${questions[cardIndex]}`)[0];

    if (titleElement) {
      titleElement.style.backgroundColor = titleColor;
    }
    if (questionElement) {
      questionElement.style.backgroundColor = questionColor;
    }
  }, [questions, cardIndex, titleColor, questionColor]);

  const handleCardZoom = async (code) => {
    console.log("test")
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getcard/${code}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();
        setCardDetails({
            code: data.code,
            name: data.name,
            question: data.question,
            answer: answers[cardIndex],
            comment: comments[cardIndex],
        });
        setShowModal(true);
    } catch (error) {
        console.error('Error fetching card details:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    console.log("test")
  }
  console.log(showModal)
  return (
    <div className="droppedcarddiv" onClick={() => handleCardZoom(questions[cardIndex])}>
      <div className={`droppedtitelbalk titel${questions[cardIndex]}`}>
        <h6 className='cardname'>{cardName[cardIndex]}</h6>
        <h6>{`|`}</h6>
        <h5>{questions[cardIndex]}</h5>
      </div>
      <div className={`droppedvraagbalk vraag${questions[cardIndex]}`}>
        <h7>{answer}</h7>
      </div>
      <div className="droppedantwoordbalk">
        <div className="droppedantwoordnotesdiv">
          <h7 className="underline">Antwoord:</h7>
          {answers[cardIndex].map((answer, idx) => (
            <h7 key={`answer-${idx}`}>{answer}</h7>
          ))}
        </div>   
        <div className="droppedantwoordnotesdiv">    
          <h7 className="underline">Notitie:</h7>
          <h7>{comments[cardIndex]}</h7>
        </div> 
      </div>
      <CardModalMatrix show={showModal} handleClose={handleCloseModal} cardDetails={cardDetails} />
    </div>
  );
};

export default RenderDroppedCard;
