import React, { useState } from 'react';

const Trainen = () => {
    const [question1, setQuestion1] = useState(false);
    const [question2, setQuestion2] = useState(false);
    const [question3, setQuestion3] = useState(false);

    const toggleQuestion = (number) => {
        switch (number) {
            case 1:
                setQuestion1(!question1);
                break;
            case 2:
                setQuestion2(!question2);
                break;
            case 3:
                setQuestion3(!question3);
                break;
            default:
                break;
        }
    }

    return (
        <div className="Spelen">
            <h5>FAQ</h5>
            <div className="faqdiv">
                <button onClick={() => toggleQuestion(1)}>Ik ben mijn wachtwoord kwijt, wat moet ik doen?<span>↓</span></button>
                {question1 && 
                <p>Als u uw wachtwoord kwijt bent, kunt u een nieuw wachtwoord aanvragen door uw spelcode in te vullen in "Wachtwoord vergeten".</p>
                }
            </div>
            <div className="faqdiv">
                <button onClick={() => toggleQuestion(2)}>Ik kan de resultaten niet downloaden, wat moet ik doen?<span>↓</span></button>
                {question2 &&
                <p>Eerst checken of uw internet verbinding het goed doet, en de pagina verversen. U kunt ook proberen uit te loggen en weer opnieuw in te loggen. In het geval dat het dan nog steeds niet werkt, dan kunt u contact opnemen.</p>
                }
            </div>
            <div className="faqdiv">
                <button onClick={() => toggleQuestion(3)}>Mijn inlogcode is verlopen. Hoe kan ik aan een nieuwe komen?<span>↓</span></button>
                {question3 &&
                <p>U kunt een e-mail sturen naar het CFPB om een verlenging aan te schaffen van uw code, of een nieuwe code aan te schaffen.</p>
                }
            </div>
        </div>
    );
};

export default Trainen;
