import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const Spelbegeleiderlogin = () => {
    const [formData, setFormData] = useState({
        spelcode: '',
        password: ''
    });
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate(); 

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            setLoggedIn(true); // Set loggedIn to true if token exists
        }
    }, []); 

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data.status === "Error") {
                alert(data.message);
                return;
            } else {
                localStorage.setItem("token", data.token);
                setLoggedIn(true);
                window.location.reload(); // Reload page upon successful login
            }
        } catch (error) {
            alert('Fout met inloggen:', error);
        }
    };

    // Redirect to '/spelenkeuze' if logged in
    if (loggedIn) {
        const token = localStorage.getItem("token");
        const decodedToken = jwtDecode(token);
        if (decodedToken.role === "Admin"){
            navigate("/admin");
        } else if (decodedToken.role === "Spelbegeleider"){
            navigate("/spelenkeuze");
        }
    }

    const backToSpelen = () => {
        navigate('/spelen'); 
    };

    return (
        <div className="login-register">
            {/* <div className="kruimelpad"> 
                <a href="/">Home</a>
                <p>/</p>
                <a href="/spelen">Login</a>
                <p>/</p>
                <a href="/login/spelbegeleider">Spelbegeleider</a>
            </div> */}
            <div className="accountdiv">
                <h5>Coach Login</h5>
                <button className="upperbuttons" onClick={backToSpelen}>Terug</button>
                <br />
                <h5>Spel Code:</h5>
                <input 
                    type="text" 
                    name="spelcode" 
                    onChange={(e) => setFormData({ ...formData, spelcode: e.target.value })} 
                    value={formData.spelcode} 
                />
                <h5>Wachtwoord:</h5>
                <input 
                    type="password" 
                    name="password" 
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })} 
                    value={formData.password} 
                />
                <button className="upperbuttons" onClick={handleSubmit}>Login</button>
                <a className="wachtwoordvergeten" href="/wachtwoord-vergeten">Wachtwoord Vergeten</a>
                <br />
                <button className="noaccount" onClick={() => navigate("/register/spelbegeleider")}>Nog geen account?</button>          
            </div>
        </div>
    );
}

export default Spelbegeleiderlogin;
