
const Navbar = () => {

    return (
        <div className="footer">
            <div className="links">
                <div className="directnaar">
                    <h1>DIRECT NAAR</h1>
                        <div className="opties">
                            <ul>
                                <li><a target="_blank" href="https://www.cfpb.nl/over-ons/" rel="noreferrer">Over ons</a></li>
                                <li><a target="_blank" href="https://www.cfpb.nl/privacy/" rel="noreferrer">Privacy</a></li>
                            </ul>
                            <ul>  
                                <li><a target="_blank" href="https://www.cfpb.nl/voorwaarden/" rel="noreferrer">Voorwaarden</a></li>
                                <li><a target="_blank" href="https://www.cfpb.nl/disclaimer/" rel="noreferrer">Disclaimer</a></li>
                            </ul>
                        </div>    
                </div>
                <div className="volgons">
                    <h1>VOLG ONS</h1>
                        <ul>
                            <li><a target="_blank" href="https://www.linkedin.com/company/center-for-people-and-buildings/" rel="noreferrer">LinkedIn</a></li>
                        </ul>
                </div>
                <div className="volgons">
                    <h1>Hulp nodig?</h1>
                        <ul>
                            <li><a target="_blank" href="/faq" rel="noreferrer">FAQ</a></li>
                            <li><a target="_blank" href="/verbeteren" rel="noreferrer">Feedback</a></li>
                        </ul>
                </div>
                <div className="contact">
                    <h1>CONTACT</h1>
                        <ul>
                            <li>
                                <p>Center for People and Buildings</p>
                                <p>Van der Burghweg 1</p>
                                <p>2628 CS Delft</p>
                            </li>
                            <li><a target="_blank" href="mailto:info@cfpb.nl" rel="noreferrer">info@cfpb.nl</a><p>+31(0)15 278 12 71</p></li>
                        </ul>
                </div>
            </div>
            <p className="trademark">© 2024 Center for People and Buildings</p>
        </div>
    )
}

export default Navbar