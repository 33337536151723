import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import ActieFile from "./../pdfs/Actie.pdf";
import BesluitFile from "./../pdfs/Besluit.pdf";    
import VoorstelFile from "./../pdfs/Voorstel.pdf";
import TegelwijsheidFile from "./../pdfs/Tegelwijsheid.pdf";
import snelstart from "./../pdfs/snelstart.pdf";
import antwoordform from "./../pdfs/antwoordform.pdf";
import handleiding from "./../pdfs/handleiding.pdf";
import handleidingkaarten from "./../pdfs/handleidingkaart.pdf";
import kaartCodeMatrix from "./../pdfs/KeuzeMatrix.xlsx";
import { jwtDecode } from 'jwt-decode';

const files = [
    { name: "Snelstart", file: snelstart },
    { name: "Actie", file: ActieFile },
    { name: "Handleiding spelbegeleider", file:handleiding},
    { name: "Besluit", file: BesluitFile },
    { name: "Handleiding Kaarten Selectie", file:handleidingkaarten},
    { name: "Voorstel", file: VoorstelFile },
    { name: "Anwoordformulier", file: antwoordform},
    { name: "Tegelwijsheid", file: TegelwijsheidFile },
    { name: "Speelkaarten Overzicht", file: kaartCodeMatrix }
];

const DownloadSpelmateriaal = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role !== "Spelbegeleider") {
                navigate("/spelen");
            }
        }
    }, []);

    return (
        <div className="downloadpage"> 
            {/*<div className="kruimelpad">
                <Link to="/">Home</Link>
                <p>/</p>
                <Link to="/spelenkeuze">Dashboard</Link>
                <p>/</p>
                <Link to="/DownloadSpelmateriaal">Download Spelmateriaal</Link>
            </div>*/}
            <button className="upperbutton2" onClick={() => navigate('/spelenkeuze')}>Vorige pagina</button>
            <h5>Op deze pagina vind je informatie en diverse hulpmiddelen om The Workplace Game goed voor te bereiden en te begeleiden tijdens het spelen.</h5>
            <div className="downloadtextcontainer">
                <div className="downloadtext">
                    <h5>Handleidingen</h5>
                    <li>Snelhandleiding voor spelers (is in doos beschikbaar)</li>
                    <li>Handleiding spelbegeleiders</li>
                    <li>Handleiding digitale rapportage</li>
                </div>
                <div className="downloadtext">
                    <h5>Selectie van spelkaarten</h5>
                    <p>Hiervoor zijn de volgende documenten beschikbaar:</p>
                    <li>Handleiding voor selecteren van spelkaarten </li>
                    <li>Overzicht van alle spelkaarten met duiding van thema, categorie en onderwerp</li>
                </div>
            </div>
            <h5>Download hieronder de bestanden:</h5>
            <div className="downloadscontainer">
                {files.map((file, index) => (
                    <div className="downloads" key={index}>
                        <a href={file.file} download>
                            <button className="upperbuttons">
                                {file.name}
                            </button>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DownloadSpelmateriaal;
