import { Link } from "react-router-dom";

const Keuzemenu = () => {
  return (
    <div className="keuzemenu">
      {/*<div className="kruimelpad">
          <a href="/">Home</a>
          <p>/</p>
          <a href="/spelen">Login</a>
      </div>*/}
      <div className="keuzes">
        <h4 style={{ color: 'black' }}>Welkom op de inlog pagina<br></br> Selecteer als welke rol je wilt inloggen</h4>
        <ul className="keuzelist">
          <li>
            <Link to="/login/spelbegeleider">Coach</Link>
          </li>
          <li>
            <Link to="/login/notulist">Rapporteur</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Keuzemenu;