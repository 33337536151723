import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Adminpaneel = () => {
    const [archivedGames, setArchivedGames] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedGames, setSelectedGames] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role !== 'Admin') {
                navigate('/');
            } else {
                fetchArchivedGames();
            }
        }
    }, []);

    // Fetch archived games
    const fetchArchivedGames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/archivedgames`);
            const data = await response.json();
            setArchivedGames(data);
        } catch (error) {
            console.error('Error fetching archived games:', error);
        }
    };

    // Filter and download games based on date range
    const filterAndDownloadGames = async () => {
        if (!startDate || !endDate) {
            alert('Please enter both start and end date.');
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/filterarchivedgames`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ startDate, endDate }),
            });
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // Ensure the filename suggests it's an Excel file
                a.download = 'filtered_games.xlsx';  
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                throw new Error('Failed to download the file.');
            }
        } catch (error) {
            console.error('Error downloading filtered games:', error);
            alert('Failed to download filtered games.');
        }
    };

    // Handle checkbox change
    const handleCheckboxChange = (gameId) => {
        setSelectedGames((prevSelectedGames) => {
            if (prevSelectedGames.includes(gameId)) {
                return prevSelectedGames.filter((id) => id !== gameId);
            } else {
                return [...prevSelectedGames, gameId];
            }
        });
    };

    // Download selected games
    const handleDownloadSelectedGames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/downloadcsv`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ gameIds: selectedGames }),
            });
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // Ensure the filename suggests it's an Excel file
                a.download = 'selected_games.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                throw new Error('Failed to download the file.');
            }
        } catch (error) {
            console.error('Error downloading selected games:', error);
            alert('Failed to download selected games.');
        }
    };

    return (
        <div className="admindiv">
            <button className="upperbuttons" onClick={() => navigate('/admin')}>Terug</button> 
            <h1>Data inzien</h1>
            <h5>Spellen van bepaald tijdvak</h5>
            <div className="gespeeldespellen">
                <div className="datefilter">
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder="Start Date"
                    />
                    <h5>tot</h5>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder="End Date"
                    />
                    <button onClick={filterAndDownloadGames}>Download</button>
                </div>
            </div>
            <h5>Alle gespeelde spellen</h5>
            <div className="gameslist">
                {archivedGames.map((game) => (
                    <div className="admingames" key={game.id}>
                        <span>{new Date(game.createdAt).toISOString().split('T')[0]}</span>
                        <input
                            type="checkbox"
                            checked={selectedGames.includes(game.id)}
                            onChange={() => handleCheckboxChange(game.id)}
                        />
                    </div>
                ))}
                {selectedGames.length > 0 && (
                    <button onClick={handleDownloadSelectedGames}>Download geselecteerde spellen</button>
                )}
            </div>
        </div>
    );
};

export default Adminpaneel;
