import React, { useState } from 'react';

const Feedback = () => {
    const [comment, setComment] = useState('');

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!comment) {
            alert("Geef een opmerking");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/addbugreport`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ details: comment }),
            });

            const responseData = await response.json();
            console.log(responseData);
            alert("Bedankt voor je feedback!");
        } catch (error) {
            console.error("Fout met afronding indienen:", error);
        }
    };

    return (
        <div className="feedbackcontainer">
            <h5>Heb je verbeterpunten gevonden?</h5>
            <textarea
                className="feedbackaera" // Keeping the original CSS class name
                placeholder="Vertel ons waar je graag verandering in wilt zien..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            />
            <button
                className="upperbuttons feedbackbuttonsave"
                onClick={handleSubmit}
            >
                Plaats opmerking
            </button>
        </div>
    );
};

export default Feedback;
