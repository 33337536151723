import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Account = () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const [bedrijf, setBedrijf] = useState("");
    const [email, setEmail] = useState("");
    const [emailPassword, setEmailPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            if (decoded.role !== "Spelbegeleider") {
                navigate('/login'); 
            } else {
                setUserId(decoded.userId);
                fetchData(decoded.userId);
            }
        } else {
            navigate('/login');
        }
    }, []);

    const fetchData = async (id) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/getuserdata/${id}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
    
                const data = await response.json();
                console.log(data);
                setBedrijf(data.company);
                setEmail(data.email);
            } catch (error) {
                console.error("Fout met gebruikersdata ophalen:", error);
            }
        };

    const handleEmailChange = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/changeemail/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newemail: newEmail, confirmemail: confirmEmail, password: emailPassword }),
            });
            const data = await response.json();
            alert(`${data.status}: ${data.message}`);
        } catch (error) {
            console.error("Fout met email veranderen:", error);
        }
    };

    const handleAccountCancellation = async () => {
        if (window.confirm("Weet u zeker dat u uw account wilt opzeggen? Dit kan niet ongedaan worden gemaakt.")) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/cancelaccount/${userId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                alert(`${data.status}: ${data.message}`);
                if (data.status === "Success") {
                    alert("Uw account is aangegeven als opgezegd. Nadat het abonnement is verlopen zal uw account worden verwijderd.");
                }
            } catch (error) {
                console.error("Fout met account opheffen:", error);
            }
        }    
    };

    const handleChangePassword = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/changepassword/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ oldPassword, newPassword, confirmPassword }),
            });
            const data = await response.json();
            alert(`${data.status}: ${data.message}`);
        } catch (error) {
            console.error("Fout met wachtwoord veranderen:", error);
        }
    };

    if (!userId) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {/* <div className="kruimelpad">
                <a href="/">Home</a>
                <p>/</p>
                <a href="/account">Account</a>
            </div> */}
            <div className="accountdiv">
                <h1>Account</h1>
                <h5>{email}</h5>
                <h5>{bedrijf}</h5>
                <div className="accountinputdiv">
                    <h5>E-mail veranderen</h5>
                    <input 
                        type="text" 
                        placeholder="Nieuw emailadres" 
                        onChange={(e) => setNewEmail(e.target.value)} 
                        value={newEmail} 
                    />
                    <input 
                        type="text" 
                        placeholder="Herhaal nieuw emailadres" 
                        onChange={(e) => setConfirmEmail(e.target.value)} 
                        value={confirmEmail} 
                    />
                    <input 
                        type="password" 
                        placeholder="Wachtwoord" 
                        onChange={(e) => setEmailPassword(e.target.value)} 
                        value={emailPassword} 
                    />
                    <button className="upperbuttons" onClick={handleEmailChange}>Aanpassen</button>
                </div>
                <div className="accountinputdiv">
                    <h5>Wachtwoord veranderen</h5>
                    <input 
                        type="password" 
                        placeholder="Huidig wachtwoord" 
                        onChange={(e) => setOldPassword(e.target.value)} 
                        value={oldPassword} 
                    />
                    <input 
                        type="password" 
                        placeholder="Nieuw wachtwoord" 
                        onChange={(e) => setNewPassword(e.target.value)} 
                        value={newPassword} 
                    />
                    <input 
                        type="password" 
                        placeholder="Herhaal nieuw wachtwoord" 
                        onChange={(e) => setConfirmPassword(e.target.value)} 
                        value={confirmPassword} 
                    />
                    <button className="upperbuttons" onClick={handleChangePassword}>Aanpassen</button>
                </div>
                {/* <input 
                    type="text" 
                    placeholder="Subscription code" 
                    onChange={(e) => setSubCode(e.target.value)} 
                    value={subCode} 
                />
                <button className="upperbuttons" onClick={handleSubscriptionExtension}>Verleng Abonnement</button> */}
                <button className="noaccount" onClick={handleAccountCancellation}>Account Opzeggen</button>     
            </div>
        </div>
    );
};

export default Account;
