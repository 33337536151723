import { useState } from "react";
import { useNavigate } from "react-router-dom";

const WachtwoordVergeten = () => {
    const [code, setCode] = useState(''); // State for storing the code input
    const navigate = useNavigate();

    // Handle form submission
    const handleSubmit = async () => {
        try {
            // POST request to your backend password reset route
            const response = await fetch(`${process.env.REACT_APP_API_URL}/request-password-reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },                
                body: JSON.stringify({ code }) // Ensure the body key matches the backend expectations
            });
            
            if (!response.ok) {
                const errorText = await response.text(); // Fetching the error message as text
                throw new Error(errorText);
            }

            const data = await response.json(); // Parsing response data as JSON
            
            if (data.status === "Error") {
                alert(data.message); 
            } else {
                alert(`Wachtwoord reset link is verstuurd naar uw email: ${code}`);
                navigate('/login/spelbegeleider'); 
            }
        } catch (error) {
            alert('Fout met het verwerken van uw aanvraag: ' + error.message); 
        }
    };

    return (
        <div className="login-register">
            <div className="accountdiv">
                <h5>Wachtwoord Reset</h5>
                <h5>Game Code:</h5>
                <input 
                    type="text" 
                    value={code} 
                    onChange={(e) => setCode(e.target.value)} 
                /> 
                <button className="upperbuttons" onClick={handleSubmit}>Verzend Link</button>
                <button className="noaccount" onClick={() => navigate(-1)}>Terug</button>
            </div>
        </div>
    );
}

export default WachtwoordVergeten;
