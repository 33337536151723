import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './AfrondingSelection.css';

const AfrondingSelection = () => {
    const { gameId, name } = useParams();
    const navigate = useNavigate();
    const [cards, setCards] = useState([]);
    const [afrondingChoices, setAfrondingChoices] = useState([]);
    const [fields, setFields] = useState([]);
    const [saveStatus, setSaveStatus] = useState(''); // State to track save status

    const cardColorMap = {
        'F': ["rgb(250,175,24)", "rgb(253,227,174)"],
        'G': ["rgb(243,110,49)", "rgb(250,204,183)"],
        'V': ["rgb(200,32,38)", "rgb(229,184,185)"],
        'R': ["rgb(144,38,143)", "rgb(216,179,215)"],
        'S': ["rgb(16,116,188)", "rgb(171,206,231)"],
        'W': ["rgb(21,192,242)", "rgb(173,223,250)"],
        'O': ["rgb(0,124,143)", "rgb(166,209,215)"],
        'A': ["rgb(58,181,74)", "rgb(186,229,191)"],
    };

    useEffect(() => {
        fetchAnswers();
    }, []);

    const fetchAnswers = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getanswers/${gameId}/${name}`);
        const data = await response.json();
        const fetchedQuestions = data.gameSession.map(card => card.cardnumber);
        const answers = data.gameSession.map(card => {
            if (card.answer_D) {
                return [...card.card_answers, `D - ${card.answer_D}`];
            } else {
                return card.card_answers;
            }
        });
        const comments = data.gameSession.map(card => card.toelichting);

        setAfrondingChoices(new Array(fetchedQuestions.length).fill('geen')); // Default to 'geen afronding nodig'
        setFields(new Array(fetchedQuestions.length).fill({})); // Initialize fields state
        fetchCardDetails(fetchedQuestions, answers, comments);
    };

    const fetchCardDetails = async (questions, answers, comments) => {
        const fetchedCards = await Promise.all(questions.map(async (question, index) => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/getcard/${question}`);
            const cardData = await response.json();
            cardData.answers = answers[index]; // Adding answers to card data
            cardData.comments = comments[index]; // Adding comments to card data
            return cardData; // Assuming the API returns an object with card details
        }));
        setCards(fetchedCards);
    };

    const handleAfrondingChange = (index, value) => {
        const newChoices = [...afrondingChoices];
        newChoices[index] = value;
        setAfrondingChoices(newChoices);

        const newFields = [...fields];
        switch (value) {
            case 'voorstel':
                newFields[index] = {
                    'Voorstel is voor': '',
                    'Voorstel': ''
                };
                break;
            case 'actie':
                newFields[index] = {
                    'Actie': '',
                    'Deadline actie': '',
                    'Actiehouder': ''
                };
                break;
            case 'besluit':
                newFields[index] = {
                    'Besluit': ''
                };
                break;
            case 'geen':
                newFields[index] = {}; // No fields needed
                break;
            default:
                newFields[index] = {};
        }
        setFields(newFields);
    };

    const handleFieldChange = (cardIndex, fieldName, value) => {
        const newFields = [...fields];
        newFields[cardIndex][fieldName] = value;
        setFields(newFields);
    };

    const submitAfrondingChoices = async () => {
        const usedCardsData = cards.map((card, index) => ({
            cardnumber: card.code,
            afronding: afrondingChoices[index],
            afrondingInhoud: fields[index], // Use afrondingInhoud instead of afronding_inhoud
        }));

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/saveUsedCards`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ usedCards: usedCardsData, gameId }), // Include gameId here
            });
            const result = await response.json();
            if (result.status === 'success') {
                setSaveStatus('Save successful');
                navigate(`/afronding/tegelwijsheid/${gameId}/${name}`);
            } else {
                setSaveStatus('Save failed');
            }
        } catch (error) {
            console.error('Error saving used cards:', error);
            setSaveStatus('Save failed');
        }
    };

    return (
        <div className="afronding-selection-container">
            <div className="cards-grid">
                {cards.map((card, index) => {
                    const [titleColor, questionColor] = cardColorMap[card.code.charAt(0)] || ["rgb(50,50,50)", "rgb(60,60,60)"];
                    return (
                        <div key={index} className="card-wrapper">
                            <div className="card">
                                <div className="titelbalk" style={{ backgroundColor: titleColor }}>
                                    <h6 className='cardname'>{card.name}</h6>
                                    <h6>|</h6>
                                    <h5>{card.code}</h5>
                                </div>
                                <div className="vraagbalk" style={{ backgroundColor: questionColor }}>
                                    <h7>{card.question}</h7>
                                </div>
                                <div className="antwoordbalk">
                                    <div className="antwoordnotesdiv">
                                        <h7 className="underline">Antwoord:</h7>
                                        {card.answers && card.answers.map((answer, idx) => (
                                            <div key={`answer-${idx}`}>
                                                <h7>{answer}</h7>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="antwoordnotesdiv">
                                        <h7 className="underline">Notitie:</h7>
                                        <h7>{card.comments || ''}</h7>
                                    </div>
                                    <div className="afronding-choice">
                                        <select value={afrondingChoices[index]} onChange={(e) => handleAfrondingChange(index, e.target.value)}>
                                            <option value="voorstel">Voorstel</option>
                                            <option value="actie">Actie</option>
                                            <option value="besluit">Besluit</option>
                                            <option value="geen">Geen afronding nodig</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="extra-fields">
                                {Object.keys(fields[index] || {}).length > 0 && (
                                    Object.keys(fields[index]).map((fieldName, fieldIndex) => (
                                        <div key={fieldIndex} className="field">
                                            <label>{fieldName}</label>
                                            {fieldName === 'Deadline actie' ? (
                                                <input
                                                    type="date" // Using date picker for the deadline field
                                                    value={fields[index][fieldName]}
                                                    onChange={(e) => handleFieldChange(index, fieldName, e.target.value)}
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    value={fields[index][fieldName]}
                                                    onChange={(e) => handleFieldChange(index, fieldName, e.target.value)}
                                                />
                                            )}
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <button className="upperbuttons" onClick={submitAfrondingChoices}>Ga naar tegelwijsheid</button>
            {saveStatus && <p>{saveStatus}</p>} {/* Display save status */}
        </div>
    );
};

export default AfrondingSelection;
