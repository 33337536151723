import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

const Archiefpagina = ({ userId }) => {
    const [archivedGames, setArchivedGames] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role === "Spelbegeleider") {
                fetchArchivedGames(decodedToken.userId);
            } else {
                navigate("/spelen");
            }
        }
    }, [navigate]);

    const fetchArchivedGames = async (userId) => {
        if (userId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/allarchived/${userId}`, {
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                if (Array.isArray(data) && data.length > 0) {
                    const closedGames = data.filter(game => game.closed);
                    setArchivedGames(closedGames);
                }
            } catch (error) {
                console.error("Error fetching archived games:", error);
            }
        } else {
            console.log('No userId to fetch games for');
        }
    };

    const downloadCSV = (gameId, sessionName) => {
        const url = `${process.env.REACT_APP_API_URL}/exportcsv/${gameId}/${sessionName}`;
        window.open(url, '_blank');
    };

    const downloadPDF = (gameId, sessionName) => {
        const url = `${process.env.REACT_APP_API_URL}/exportpdf/${gameId}/${sessionName}`;
        window.open(url, '_blank');
    };

    const backToDashboard = () => {
        navigate('/dashboard'); 
    };

    const formatDate = (dateString) => {
        if (!dateString) return "Unknown date";
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? "Invalid date" : date.toISOString().split('T')[0];
    };

    return (
        <div className="dashboarddiv">
            <button className="upperbuttons" onClick={backToDashboard}>Naar Spelsessies beheren</button>
            <h1>Afgeronde Games</h1>
            
            {archivedGames.length > 0 ? archivedGames.map((game) => (
                <div key={game.id} className='ongoinggame'>
                    <div className="ongoinggameheader">
                        <p>{game.title}</p>
                        <p>{formatDate(game.createdAt)}</p>
                    </div>
                    {game.closed && (
                        <div className="ongoinggamesession" key={game.id}>
                            <p>{`Session A`}</p>
                            <button onClick={() => downloadCSV(game.id, 'A')}>
                                Download CSV A
                            </button>
                            {/* <button onClick={() => downloadPDF(game.id, 'A')}>
                                Download PDF A
                            </button> */}
                            {game.sessionBPresent && (
                                <>
                                    <p>{`Session B`}</p>
                                    <button onClick={() => downloadCSV(game.id, 'B')}>
                                        Download CSV B
                                    </button>
                                    {/* <button onClick={() => downloadPDF(game.id, 'B')}>
                                        Download PDF B
                                    </button> */}
                                </>
                            )}
                        </div>
                    )}
                </div>
            )) : <p>Geen afgeronde games gevonden.</p>}
        </div>
    );
};

export default Archiefpagina;
