import React, { useState, useEffect } from 'react';
import './table.css';
import CardModal from './CardModal';

function KaartTabel(props) {
    const [rowData, setRowData] = useState([]);
    const [highlightedRows, setHighlightedRows] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [cardDetails, setCardDetails] = useState({});

    const columnDefs = [
        { field: 'Code' },
        ...props.selectedSubjects.map(subject => ({ field: subject }))
    ];

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/gridcards`)
            .then(result => result.json())
            .then(data => {
                if (props.selectedSubjects.length > 0) {
                    // Filter out rows where none of the selected subjects are present
                    const filteredData = data.filter(row =>
                        props.selectedSubjects.some(subject => row[subject] === 'X')
                    );

                    const highlighted = {};

                    filteredData.forEach(row => {
                        const matchCount = props.selectedSubjects.filter(subject => row[subject] === 'X').length;
                        if (matchCount > 1) {
                            highlighted[row.Code] = matchCount;
                        }
                    });

                    setRowData(filteredData);
                    setHighlightedRows(highlighted); // Set highlighted rows with match count
                } else {
                    // If no subjects are selected, show all rows without highlighting
                    setRowData(data);
                    setHighlightedRows({});
                }
            })
            .catch(err => console.error('Error fetching data:', err));
    }, [props.selectedSubjects]);

    const handleRowClick = async (code) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/getcard/${code}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            setCardDetails({
                code: data.code,
                name: data.name,
                question: data.question,
                optieA: data.optieA,
                optieB: data.optieB,
                optieC: data.optieC,
                optieD: data.optieD
            });
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching card details:', error);
        }
    };

    const handleCloseModal = () => setShowModal(false);

    const getHighlightClass = (matchCount) => {
        switch (matchCount) {
            case 2:
                return 'highlighted-row-2';
            case 3:
                return 'highlighted-row-3';
            case 4:
                return 'highlighted-row-4';
            case 5:
                return 'highlighted-row-5';
            case 6:
                return 'highlighted-row-6';
            default:
                return 'highlighted-row';
        }
    };
    

    return (
        <>
            <table className='styled-table'>
                <thead>
                    <tr>
                        {columnDefs.map(column => (
                            <th key={column.field}>
                                {column.field}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rowData.map(row => (
                        <tr key={row.Code} 
                            className={highlightedRows[row.Code] ? getHighlightClass(highlightedRows[row.Code]) : ''} 
                            onClick={() => handleRowClick(row.Code)}>
                            {columnDefs.map(column => (
                                <td key={column.field}>
                                    {row[column.field]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <CardModal show={showModal} handleClose={handleCloseModal} cardDetails={cardDetails} />
        </>
    );
}

export default KaartTabel;
