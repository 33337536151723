import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Adminpaneel = () => {
    const [numCodes1, setNumCodes1] = useState('');
    const [spelCode, setSpelCode] = useState('');
    const [bedrijf, setBedrijf] = useState('');
    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role !== 'Admin') {
                navigate('/');
            }
        }
    }, []);

    const generateSpelcodes = async () => {
        if (numCodes1 < 1) {
            alert('Voer een getal groter dan 0 in');
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/generatespelcodes`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ numCodes1 }),
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'spelcodes.csv';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error generating codes:', error);
        }
    };

    const zoekGebruiker = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/zoekgebruiker`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ spelCode }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
                setEmail("");
                setBedrijf("");
                setUserId("");
            } else {
                setEmail(data.email);
                setBedrijf(data.bedrijf);
                setUserId(data.userId);
            }
        } catch (error) {
            console.error('Error searching for user:', error);
        }
    };

    const verlengAbonnement = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/extendsubscription/${userId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                alert('Abonnement verlengd');
            }
        } catch (error) {
            console.error('Error extending subscription:', error);
        }
    }
 
    return (
        <div className="admindiv">
            <button className="upperbuttons" onClick={() => navigate('/admin')}>Terug</button>
            <h1>Spelcodes aanmaken en Abonnement verlengen</h1>
            <h5>Spelcodes</h5>
            <div className="generatordiv">
                <div className="codegenerator">
                    <div className='admincodeinput'>
                        <input
                            type="number"
                            value={numCodes1}
                            placeholder='Aantal codes'
                            onChange={(e) => setNumCodes1(e.target.value)}
                        />
                        <button onClick={generateSpelcodes}>Download CSV</button>
                    </div>
                </div>
            </div>
            <h5>Abonnement verlengen</h5>
            <div className="generatordiv">
                <div className="codegenerator">
                    <div className='admincodeinput'>
                        <input
                            type="number"
                            value={spelCode}
                            placeholder='Spelcode'
                            onChange={(e) => setSpelCode(e.target.value)}
                        />
                        <button onClick={zoekGebruiker}>Zoek gebruiker</button>
                    </div>
                    {email && <p>Email: {email}</p>}
                    {bedrijf && <p>Bedrijf: {bedrijf}</p>}
                    {(email || bedrijf) && <button onClick={verlengAbonnement}>Verleng Abonnement</button>}
                </div>
            </div>
        </div>
    );
};

export default Adminpaneel;
