import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Notes = () => {
    const { gameId, name } = useParams();
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [length, setLength] = useState(0);
    const [index, setIndex] = useState(0);
    const [note, setNote] = useState("");
    const [notes, setNotes] = useState([]);
    const [comments, setComments] = useState([]);
    const [afronding, setAfronding] = useState([]);
    const [afrondingInhoud, setAfrondingInhoud] = useState([]);
    const [code, setCode] = useState("");
    const [cardName, setCardName] = useState("");
    const [question, setQuestion] = useState("");
    const [matrix, setMatrix] = useState([]);
    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/archived/${gameId}/${name}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const data = await response.json();
            setQuestions(data.usedCards.map(card => card.cardnumber) || []);
            setAnswers(data.usedCards.map(card => {
                if (card.answer_D) {
                    return [...card.card_answers, `D - ${card.answer_D}`];
                } else {
                    return card.card_answers;
                }
            }) || []);
            setComments(data.usedCards.map(card => card.toelichting) || []);
            setAfronding(data.usedCards.map(card => card.afronding) || []);
            setAfrondingInhoud(data.usedCards.map(card => card.afrondingInhoud) || []);
            setMatrix(data.usedCards.map(card => card.matrix) || []);
            setNotes(new Array((data.usedCards || []).length).fill(""));
            setLength((data.usedCards || []).length);
            if (data.usedCards && data.usedCards.length > 0) {
                getCard(data.usedCards[0].cardnumber);
            }
        } catch (error) {
            console.error("Fout met gebruikers data ophalen:", error);
        }
    }, [gameId, name]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            if (decoded.role !== "Spelbegeleider") {
                navigate('/spelen');
            } else {
                fetchData();
            }
        } else {
            navigate('/spelen');
        }
    }, [navigate, fetchData]);

    const getCard = async (code) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/getcard/${code}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            setCode(data.code);
            setQuestion(data.question);
            setCardName(data.name);

            const titleElement = document.getElementsByClassName("titelbalk")[0];
            const questionElement = document.getElementsByClassName("vraagbalk")[0];
            const colorMap = {
                'F': ["rgb(250,175,24)", "rgb(253,227,174)"],
                'G': ["rgb(243,110,49)", "rgb(250,204,183)"],
                'V': ["rgb(200,32,38)", "rgb(229,184,185)"],
                'R': ["rgb(144,38,143)", "rgb(216,179,215)"],
                'S': ["rgb(16,116,188)", "rgb(171,206,231)"],
                'W': ["rgb(21,192,242)", "rgb(173,223,250)"],
                'O': ["rgb(0,124,143)", "rgb(166,209,215)"],
                'A': ["rgb(58,181,74)", "rgb(186,229,191)"],
                'default': ["rgb(50,50,50)", "rgb(60,60,60)"]
            };
            const colors = colorMap[data.code.charAt(0)] || colorMap['default'];
            if (titleElement && questionElement) {
                titleElement.style.backgroundColor = colors[0];
                questionElement.style.backgroundColor = colors[1];
            }
        } catch (error) {
            console.error('Fout met kaart ophalen:', error);
        }
    };

    const handleNext = async () => {
        if (note) {
            const newNotes = [...notes];
            newNotes[index] = note;
            setNotes(newNotes);
            await handleSubmit(newNotes);
            const nextIndex = index + 1;
            if (nextIndex < length) {
                setIndex(nextIndex);
                getCard(questions[nextIndex]);
                setNote(newNotes[nextIndex] || "");
            }
        } else {
            alert("Vul een opmerking in");
        }
    };

    const handleEnd = async () => {
        if (!note) {
            alert("Vul een opmerking in");
            return;
        }
        let newNotes = [...notes];
        newNotes[index] = note;
        if (window.confirm("Weet u zeker dat u de notities wilt afsluiten?")) {
            await handleSubmit(newNotes);
            try {
                const token = localStorage.getItem('token');
                const decoded = jwtDecode(token);
                const userId = decoded.userId;

                const response = await fetch(`${process.env.REACT_APP_API_URL}/closenotes/${gameId}/${name}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ userId })
                });

                const data = await response.json();
                console.log(data);
            } catch (error) {
                console.error("Fout met gebruikers data ophalen:", error);
            }
            navigate('/dashboard');
        }
    };

    const handleSubmit = async (newNotes) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/setnotes/${gameId}/${name}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ notes: newNotes }),
            });

            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error("Fout met gebruikers data ophalen:", error);
        }
    };

    const handlePrevious = async () => {
        if (index > 0) {
            const newNotes = [...notes];
            newNotes[index] = note;
            setNotes(newNotes);
            await handleSubmit(newNotes);
            const prevIndex = index - 1;
            setIndex(prevIndex);
            getCard(questions[prevIndex]);
            setNote(newNotes[prevIndex]);
        }
    };

    return (
        <div className="notespage">
            
                {questions.length > 0 ? (
                    <>
                        <div className="notes">
                            <div className="titelbalk">
                                <h6 className='cardname'>{` ${cardName}`}</h6>
                                <h6>{`|`}</h6>
                                <h5>{`${code}`}</h5>
                            </div>
                            <div className="vraagbalk">
                                <h7>{question}</h7>
                            </div>
                            <div className="antwoordbalk">
                                <div className="antwoordnotesdiv">
                                    <h7 className="underline">Antwoord:</h7>
                                    {answers[index] && answers[index].map((answer, idx) => (
                                        <h7 key={`answer-${idx}`}>{answer}</h7>
                                    ))}
                                </div>
                                <div className="antwoordnotesdiv">
                                    <h7 className="underline">Toelichting:</h7>
                                    <h7>{comments[index] || ''}</h7>
                                </div>
                                <div className="antwoordnotesdiv">
                                    <h7 className="underline">Matrixkeuze:</h7>
                                    <h7>{matrix[index] || ''}</h7>
                                </div>
                                <div className="antwoordnotesdiv">
                                    <h7 className="underline">Afronding:</h7>
                                    <h7>{afronding[index] || ''}</h7>
                                </div>
                                <div className="antwoordnotesdiv">
                                    <h7 className="underline">Afronding Inhoud:</h7>
                                    <h7>
                                        {afrondingInhoud[index] ? 
                                            Object.entries(afrondingInhoud[index]).map(([key, value]) => (
                                                <div key={key}>{`${key}: ${value}`}</div>
                                            )) 
                                            : ''}
                                    </h7>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <p>Loading...</p>
                )}
            
            <div className="notesform">
                <h7>Opmerking:</h7>
                <textarea type="text" placeholder="Vul hier uw opmerking in..." value={note} onChange={(e) => setNote(e.target.value)} />
                <div className="notesbuttons">
                    {index !== 0 && (
                        <button onClick={handlePrevious}>Vorige vraag</button>
                    )}
                    {index === length - 1 ? (
                        <button onClick={handleEnd}>Beeïndig</button>
                    ) : (
                        <button onClick={handleNext}>Volgende vraag</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Notes;
