import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import ActieFile from "./../pdfs/Actie.pdf";
import BesluitFile from "./../pdfs/Besluit.pdf";
import VoorstelFile from "./../pdfs/Voorstel.pdf";
import TegelwijsheidFile from "./../pdfs/Tegelwijsheid.pdf";

const Dashboard = () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState("");
    const [newGameTitle, setNewGameTitle] = useState("");
    const [archivedGames, setArchivedGames] = useState([]);
    const [activeGames, setActiveGames] = useState([]);
    const [notulistCodes, setNotulistCodes] = useState({ codeA: '', codeB: '' });
    const [numSessions, setNumSessions] = useState(1);
    const [sessionCount, setSessionCount] = useState(0);
    const [gameName, setGameName] = useState("");
    const [mode, setMode] = useState('');
    const [content, setContent] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            setUserId(decodedToken.userId);
            if (decodedToken.role === "Spelbegeleider") {
                fetchArchivedGames(decodedToken.userId);
                fetchActiveGames(decodedToken.userId);
                fetchUserDetails(decodedToken.userId);
            } else {
                navigate("/spelen");
            }
        }
    }, [navigate]);

    const handleModeChange = (e) => {
        const selectedMode = e.target.value;
        setMode(selectedMode);
        setContent(selectedMode === 'offline' ? "Afronding is offline uitgevoerd." : "");
    };

    const fetchUserDetails = async (userId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`);
            const userData = await response.json();
            setNotulistCodes({
                codeA: userData.writerCodeA,
                codeB: userData.writerCodeB
            });
            setSessionCount(userData.sessionCount);
            setGameName(userData.name);
        } catch (error) {
            console.error("Fout met gebruikers gegevens ophalen:", error);
        }
    };

    const fetchArchivedGames = async (userId) => {
        if (userId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/archived/${userId}`, {
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                setArchivedGames(data.filter(game => !game.closed));
            } catch (error) {
                console.error("Fout met gebruikers gegevens ophalen:", error);
            }
        } else {
            console.log('Geen gebruiker om gegevens voor op te halen');
        }
    };

    const fetchActiveGames = async (userId) => {
        if (userId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/activegames/${userId}`, {
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                setActiveGames(data);
            } catch (error) {
                console.error("Fout met actieve games ophalen:", error);
            }
        } else {
            console.log('Geen gebruiker om gegevens voor op te halen');
        }
    };

    const handleCreateGame = async (e) => {
        e.preventDefault();
        if (!newGameTitle) {
            alert("Vul een titel in");
            return;
        }
        if (!mode) {
            alert("Selecteer een mode");
            return;
        }
        if (activeGames.length > 0) {
            alert("Er is al een actief spel. Sluit het huidige spel voordat u een nieuw spel aanmaakt.");
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/create/${userId}`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: newGameTitle,
                    numSessions: numSessions,
                    mode: mode,
                    content: content,
                }),
            });
            window.location.reload();
        } catch (error) {
            console.error("Fout met game aanmaken:", error);
        }
    };

    const openGame = (gameId, name) => {
        navigate(`/notes/${gameId}/${name}`);
    };

    const closeGame = async (gameId, name) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/closenotes/${gameId}/${name}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            window.location.reload();
        } catch (error) {
            console.error("Error closing the game:", error);
            alert('Failed to close the game');
        }
    };

    const gotoArchief = () => {
        navigate('/Archiefpagina');
    };

    const goToPreviousPage = () => {
        navigate("/spelenkeuze");
    };

    const handleCloseGame = async () => {
        if (window.confirm("Deze optie is bedoeld om het spel geforceerd te sluiten wanneer er iets is misgegaan en het spel niet kan worden afgerond. De antwoorden die tot het punt van sluiten zijn ingevuld, gaan niet verloren. Weet je zeker dat je het spel wilt sluiten?")) {
            const token = localStorage.getItem("token");
            if (!token) {
                alert("User not logged in");
                return;
            }
            const { userId } = jwtDecode(token);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/closegame`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId }),
                });
                const data = await response.json();
                if (data.success) {
                    alert('Game successfully closed');
                    window.location.reload();
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                console.error("Error closing the game:", error);
                alert('Failed to close the game');
            }
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert("Code gekopieerd: " + text);
        }).catch((err) => {
            console.error("Fout bij kopiëren naar klembord:", err);
        });
    };

    return (
        <div>
            <div className="dashboarddiv">
                <button className="upperbuttons" onClick={goToPreviousPage}>Vorige pagina</button>
                <h1>Spelsessies beheren</h1>
                {activeGames.length === 0 ? (
                    <>
                        <div className="generatordiv">
                            <h5>nieuw spel:</h5>
                            <input type="text" placeholder="Titel van spel" onChange={(e) => setNewGameTitle(e.target.value)} value={newGameTitle} />
                            <div className="sessioninputs">
                                <div>
                                    <input
                                        type="radio"
                                        value="1"
                                        checked={numSessions === 1}
                                        onChange={() => setNumSessions(1)}
                                    />
                                    <p>1 groep</p>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        value="2"
                                        checked={numSessions === 2}
                                        onChange={() => setNumSessions(2)}
                                    />
                                    <p>2 groepen</p>
                                </div>
                            </div>
                            <div className="dashboardmode">
                                <p>Afronding:</p>
                                <select value={mode} onChange={handleModeChange}>
                                    <option value="">Selecteer Afronding optie</option>
                                    <option value="online">Online</option>
                                    <option value="offline">Offline</option>
                                </select>
                            </div>
                            {mode === 'offline' && (
                                <div className="dashboardoffline">
                                    <p>Download afrondfase:</p>
                                    <div>
                                        <a href={TegelwijsheidFile} download="Tegelwijsheid">Tegelwijsheid</a>
                                        <a href={VoorstelFile} download="Voorstel">Voorstel</a>
                                    </div>
                                    <div>
                                        <a href={ActieFile} download="Actie">Actie</a>
                                        <a href={BesluitFile} download="Besluit">Besluit</a>
                                    </div>
                                    <br></br>
                                </div>
                            )}
                            <button className="upperbuttons" onClick={handleCreateGame}>Maak spel aan</button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="generatordiv">
                            <h5>Huidig spel: {gameName}</h5>
                            <p>Stuur de volgende code(s) naar de rapporteurs</p>
                            <p>Notulist Code A: {notulistCodes.codeA}
                                <button className="copybutton" onClick={() => copyToClipboard(notulistCodes.codeA)}>
                                    Kopieer
                                </button>
                            </p>
                            {sessionCount > 1 && <p>Notulist Code B: {notulistCodes.codeB}
                                <button className="copybutton" onClick={() => copyToClipboard(notulistCodes.codeB)}>
                                    Kopieer
                                </button>
                            </p>}
                            <button className="upperbuttons" onClick={handleCloseGame}>Sluit spel</button>
                        </div>
                    </>
                )}

                <button className="upperbuttons" onClick={gotoArchief}>Ga naar rapport overzicht</button>
                <div className="generatordiv">
                    <h5>Klaar voor noteren:</h5>
                    {archivedGames.length > 0 ? (
                        <>
                            {archivedGames.map((game) => (
                                <div key={game.id} className="ongoinggame">
                                    <div className="ongoinggameheader">
                                        <p>{game.title}</p>
                                        <p>{new Date(game.createdAt).toISOString().split('T')[0]}</p>
                                    </div>
                                    {game.activeA && (
                                        <div className="ongoinggamesession">
                                            <p>Sessie A</p>
                                            <button onClick={() => openGame(game.id, 'A')}>Open spel A</button>
                                            <button onClick={() => closeGame(game.id, 'A')}>Sluit spel A</button>
                                        </div>
                                    )}
                                    {game.sessionBPresent && game.activeB && (
                                        <div className="ongoinggamesession">
                                            <p>Sessie B</p>
                                            <button onClick={() => openGame(game.id, 'B')}>Open spel B</button>
                                            <button onClick={() => closeGame(game.id, 'B')}>Sluit spel B</button>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </>
                    ) : (
                        <p>Geen actieve spellen gevonden</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
